<template>
    <page-layout-nolo :loading="isLoading" :title="title" :back="$t('btn.backToYourAccount')">
        <v-container>
            <v-card class="mb-10 elevation-8 pa-4">
                <v-card-text>
                    <address-edit v-model="editAddress" @valid="value => isValid = value"></address-edit>
                    <v-switch :label="$t('label.makeCreditCardAddress')"
                              :hint="$t('hint.creditCardAddress')"
                              :readonly="lockDefaultAddress" v-model="defaultAddress"/>
                </v-card-text>
                <v-card-actions class="pt-0 px-4 pb-6">
                    <v-row dense>
                        <v-col cols="12" sm="4" md="3" class="pt-0 pb-2 pr-md-6" v-if="!lockDefaultAddress && !adding">
                            <v-btn @click="deleteAddressAsk" outlined block color="primary" class="mr-2">{{ $t('btn.delete') }}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pt-0 pb-2">
                            <v-btn @click="cancel" outlined block color="secondary">{{ $t('btn.cancel') }}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0 pb-2">
                            <v-btn :disabled="!isValid" @click="save" block color="primary">{{ $t('btn.saveAddress') }}</v-btn>
                        </v-col>
                    </v-row>

                </v-card-actions>
            </v-card>
        </v-container>
        <yes-no-dialog @yes="deleteAddress" v-model="showYesNo" color="primary"/>

    </page-layout-nolo>
</template>

<script>
    import AddressEdit from "../../components/Account/AddressEdit";
    import APICustomer from "../../api/APICustomer";
    import YesNoDialog from "../../components/UI/YesNoDialog";

    export default {
        name: "EditAddress",
        components: {YesNoDialog, AddressEdit},
        data() {
            return {
                showYesNo: false,
                isLoading: false,
                isSaving: true,
                isValid: false,
                defaults: {},
                editAddress: null,
                defaultAddress: false
            }
        },
        mounted() {
            if (this.adding) {
                this.editAddress = {
                    "firstname": "",
                    "lastname": "",
                    "address_1": "",
                    "address_2": "",
                    "postcode": "",
                    "city": "london",
                    "zone_id": "0",
                    "zone": "Greater London",
                    "zone_code": "LDN",
                    "country_id": "222",
                    "country": "United Kingdom",
                    "iso_code_2": "GB",
                    "iso_code_3": "GBR",
                    "address_format": ""
                }
            } else {
                this.editAddress = this.customer.addresses[this._indexForAddressId(this.addressNo)];
                // noinspection EqualityComparisonWithCoercionJS
                this.defaultAddress = this.customer.addressId == this.addressNo;
            }
        },
        methods: {
            cancel() {
                this.$router.back();
            },
            save() {
                const me = this;
                APICustomer.updateAddress(this, this.editAddress, this.defaultAddress)
                    .then(() => {
                        let newCustomer = {
                            ...me.customer,
                        };
                        newCustomer.addresses[this._indexForAddressId(this.addressNo)] = this.editAddress;
                        if (this.defaultAddress) {
                            newCustomer.addressId = this.addressNo;
                        }
                        me.customer = newCustomer;
                        me.$router.back();
                    })
                    .catch(
                        () => {
                            me.appShowOops()
                        }
                    )
            },
            deleteAddressAsk() {
                this.showYesNo = true;
            },
            deleteAddress() {
                const me = this;
                APICustomer.removeAddress(this, this.addressNo)
                    .then(() => {
                        let newCustomer = {
                            ...me.customer,
                        };
                        newCustomer.addresses.splice(this._indexForAddressId(this.addressNo), 1);
                        me.customer = newCustomer;
                        me.$router.back();
                    })
                    .catch(
                        () => {
                            me.appShowOops()
                        }
                    )
            },

            _indexForAddressId(addressId) {
                for (let idx in this.customer.addresses) {
                    if (this.customer.addresses[idx].address_id === addressId) return idx;
                }
                return 0;
            }
        },
        computed: {
            lockDefaultAddress() {
                return this.customer.addressId === this.addressNo;
            },
            adding() {
                return this.addressNo === 'add';
            },
            title() {
              return this.adding ? this.$t('addAddress') : this.$t('editAddress');
            },
            addressNo() {
                return this.$route.params.id;
            },
            customer: {
                get() {
                    return this.$store.getters['session/customer'];
                },
                set(newValue) {
                    this.$store.commit('session/customer', newValue)
                }
            }
        },
    }
</script>

<style scoped>

</style>
